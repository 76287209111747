import React from 'react'
import './Solution.scss'

const Solution = () => (
  <div className="solution-container">
    <div className="solution-box">
      <h3 className="solution-header">
        <span className="solution-header-light">ONLINE CRISIS.</span> REAL WORLD SOLUTION.
      </h3>
      <p className="solution-text">
        Our advantage is a potent combination of leading-edge technology partnerships, extensive
        industry experience, data-led insight, and exceptional talent.
      </p>
    </div>
  </div>
)

export default Solution
