import React from 'react'
import { RenderPostRow } from '..'
import Divider from '../../elements/Divider'

import './index.scss'

interface IPostDetailsProps {
  data: Queries.singleBlogPostQueryQuery
}

const PostDetails: React.FC<IPostDetailsProps> = ({ data }) => {
  const {
    content,
    src: { url },
    tag,
    title,
    createdAt
  } = data?.contentfulBlogPost || {}

  const randomPosts = data?.allContentfulBlogPost?.edges.splice(
    Math.floor(Math.random() * data?.allContentfulBlogPost?.edges.length),
    2
  )

  return (
    <div className="card-detail">
      <div className={`card-detail-container`}>
        <span className="card-detail-container__tag">{tag}</span>
        <div className="card-detail-container__title">{title}</div>
        <div className="card-detail-container__date">{createdAt}</div>
        <div className="card-detail-container-image">
          <img className="card-detail-container-image__src" src={url} alt={'test'} />
        </div>
        <div className="card-detail-container__description">
          <div dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }} />
        </div>
      </div>
      <Divider />
      <div className="card-detail-related">
        <div className="card-detail-related-title">Continue reading the latest news</div>
        <div className="card-detail-related-container">
          <RenderPostRow blogs={randomPosts} />
        </div>
      </div>
    </div>
  )
}

export default PostDetails
