import React, { FC } from 'react'
import Card from '../elements/Card'

import './index.scss'

interface IRenderPostRowProps {
  blogs: Queries.allPostsQueryQuery['allContentfulBlogPost']['edges']
}

export const RenderPostRow: FC<IRenderPostRowProps> = ({ blogs }) => (
  <>
    {blogs?.map((post) => (
      <Card key={post?.node.id} item={post?.node} />
    ))}
  </>
)

interface IBlogContainerProps {
  data: Queries.allPostsQueryQuery['allContentfulBlogPost']['edges']
}

const BlogContainer: FC<IBlogContainerProps> = ({ data }) => (
  <div className="insight-container">
    <div className="insight-container-blogs">
      <RenderPostRow blogs={data} />
    </div>
  </div>
)

export default BlogContainer
