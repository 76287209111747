import React from 'react'
import './index.scss'

interface Props {
  name: string
  label: string
  value?: string | number
  setValue: React.Dispatch<React.SetStateAction<boolean>>
}

const Checkbox: React.FC<Props> = ({ name, label, value, setValue }) => {
  return (
    <div className="check-container">
      <input
        type={'checkbox'}
        checked={value == 'true' ? true : false}
        name={name}
        value={value}
        onClick={() => {
          setValue(value == 'true' ? false : true)
        }}
      />
      <label>{label}</label>
    </div>
  )
}

export default Checkbox
