import * as React from 'react'
import { HeadFC, Link } from 'gatsby'
import Banner from '../components/Banner'
import Button from '../components/elements/Button'
import useWindowDimensions from '../hooks/useWindowDimensions'
import '../components/_variables.scss'

interface IStyles {
  [key: string]: string | number
}

const notFoundContainer: IStyles = {
  height: '100vh',
  backgroundColor: '#66141D'
}

const notFoundBanner: IStyles = {
  position: 'absolute',
  top: '50%',
  left: '0%',
  transform: 'translate(0%, -50%)',
  webkitTransform: 'translate(0%, -50%)',
  textAlign: 'center',
  padding: '0em 1em'
}

const notFoundHeaderLight: IStyles = {
  fontSize: '48px',
  fontFamily: 'AvertaLight'
}

const notFoundMessageNow: IStyles = {
  marginTop: '1em'
}

const NotFoundPage = () => {
  const { width } = useWindowDimensions()

  const notFoundHeader: IStyles = {
    padding: width < 1208 ? '0em .5em' : '0em 5em 0em',
    fontSize: '48px',
    textAlign: 'center',
    fontFamily: 'AvertaSemiBold',
    lineHeight: '48.09px',
    color: '#fff'
  }

  return (
    <Banner>
      <div style={notFoundContainer}>
        <div style={notFoundBanner}>
          <h3 style={notFoundHeader}>
            <span style={notFoundHeaderLight}>A LOT CAN </span>CHANGE IN THE CLICK OF A BUTTON.
          </h3>
          <div style={notFoundMessageNow}>
            <Link to="/">
              <Button
                title="GO HOME"
                style={{
                  background: 'white',
                  border: '1px solid #fff',
                  color: '#66141D',
                  width: '100%',
                  cursor: 'pointer',
                  ...(width > 740 && {
                    width: '20%'
                  })
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </Banner>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>
