import React from 'react'
import Banner from '../Banner'
import ReachOut from '../ReachOut'
import TeamCard from './TeamCard'
import './index.scss'

interface IAboutProps {
  data: Queries.AboutPageQuery
}

const About: React.FC<IAboutProps> = ({ data }) => {
  const aboutData = data.allContentfulEmployees.edges

  return (
    <div>
      <Banner>
        <section className="about-container">
          <div className="about-hero">
            <h3 className="about-hero-title">
              Our AMBITION is to be Africa’s leading
              <span className="about-hero-title-bold">
                {' '}
                solution for reputation and community management.
              </span>
            </h3>
          </div>
        </section>
      </Banner>
      <div className="about-team">
        {aboutData.map(({ node: { id, bio, linkedIn, messageUrl, name, twitter, image } }) => (
          <TeamCard
            key={id}
            {...{
              bio,
              linkedIn,
              messageUrl,
              name,
              twitter,
              image
            }}
          />
        ))}
      </div>
      <ReachOut>
        <h3 className="reach-out-header">
          <span className="reach-out-header-light">
            48H helps companies lower their risk of costly{' '}
          </span>
          reputational harm.
        </h3>
      </ReachOut>
    </div>
  )
}

export default About
