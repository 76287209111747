import React from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Banner from '../Banner'
import Button from '../elements/Button'
import FormSection from './FormSection'
import './index.scss'

const Contact = () => {
  const { width } = useWindowDimensions()

  const email = 'info@48h.co.za'

  return (
    <div className="contact-container">
      <Banner>
        <div className="contact-banner">
          <h3 className="contact-header">
            <span className="contact-header-light">A LOT CAN </span>CHANGE IN 48HOURS.
          </h3>
          <div>
            <a href={`mailto:${email}`} title="email" className="contact-email">
              EMAIL
            </a>
          </div>
          <div className="contact-message-now">
            <a href={`mailto:${email}`}>
              <Button
                title="MESSAGE NOW"
                className="contact-button-message"
                style={{
                  ...(width > 740 && {
                    width: '20%'
                  })
                }}
              />
            </a>
          </div>
        </div>
      </Banner>
      <FormSection />
    </div>
  )
}

export default Contact
