import * as React from 'react'
import { graphql, HeadFC } from 'gatsby'
import Layout from '../components/layout'
import About from '../components/About'
import HtmlHead from '../components/HtmlHead'

const AboutPage = ({ data }: { data: Queries.AboutPageQuery }) => (
  <Layout>
    <About data={data} />
  </Layout>
)

export default AboutPage

export const Head: HeadFC = () => <HtmlHead pageTitle="About Page" />

export const aboutPage = graphql`
  query AboutPage {
    allContentfulEmployees {
      edges {
        node {
          id
          name
          linkedIn
          twitter
          messageUrl
          bio {
            bio
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
