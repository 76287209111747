import * as React from 'react'
import Layout from '../components/layout'
import BlogContainer from '../components/Blog'
import Banner from '../components/Banner'
import { graphql, HeadFC, useStaticQuery } from 'gatsby'
import HtmlHead from '../components/HtmlHead'

export interface IPost {
  image: string
  tag: string
  title: string
  content: string
  feature?: boolean
}
const InsightsPage = () => {
  const data: Queries.allPostsQueryQuery = useStaticQuery(allPostsQuery)

  return (
    <Layout>
      <Banner>
        <BlogContainer data={data?.allContentfulBlogPost?.edges} />
      </Banner>
    </Layout>
  )
}

export default InsightsPage

export const Head: HeadFC = () => <HtmlHead pageTitle="Insights Page" />

export const allPostsQuery = graphql`
  query allPostsQuery {
    allContentfulBlogPost(limit: 9) {
      edges {
        node {
          title
          tag
          id
          content {
            content
          }
          src {
            url
            title
          }
          slug
          createdAt(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`
