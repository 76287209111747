import React, { ChangeEvent } from 'react'
import './index.scss'

type TTxtAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  label: string
}

const TxtArea: React.FC<TTxtAreaProps> = ({ id, value, label, onChange, style, required }) => {
  const [hasValue, setHasValue] = React.useState<boolean>(!!value)
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e)
    setHasValue(!!e.target.value)
  }

  return (
    <div className="txtarea-container" style={style}>
      <textarea
        id={id}
        value={value}
        rows={10}
        cols={50}
        name={id}
        className={`txtarea-input ${hasValue ? 'filled' : ''}`}
        onChange={handleOnChange}
        required={required}
      />
      <label htmlFor={id} className="txtarea-label">
        {label}
      </label>
    </div>
  )
}

export default TxtArea
