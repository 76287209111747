import React, { useState } from 'react'
import ResponsiveOverlay from '../ResponsiveOverlay'
import SocialsIcons from '../SocialsIcons'

import './TeamCard.scss'

interface ITeamCardProps {
  name: string | null
  bio: { readonly bio: string | null } | null
  twitter: string | null
  linkedIn: string | null
  messageUrl: string | null
  image: { readonly file: { readonly url: string | null } | null } | null
}

const TeamCard: React.FC<ITeamCardProps> = (props) => {
  const { bio, linkedIn, messageUrl, name, twitter, image } = props
  const [isReadMoreExpanded, setIsReadMoreExpanded] = useState<boolean>(false)

  return (
    <>
      <div className="team-card-container">
        <div className="team-card-section">
          <img src={`https://${image?.file?.url}`} alt="Avatar" className="team-card-section-img" />
          <div className="half-circle"></div>
          <div className="team-card-text-group">
            <h4 className="team-card-text-name">
              <b>{name}</b>
            </h4>
            <p className="team-card-text-desc">{bio?.bio}</p>
            <button
              onClick={() => setIsReadMoreExpanded(!isReadMoreExpanded)}
              className="team-card-read-more"
            >
              Read More
            </button>
          </div>
        </div>
      </div>
      <ResponsiveOverlay
        show={isReadMoreExpanded}
        onClick={() => setIsReadMoreExpanded(!isReadMoreExpanded)}
        closeContent="&times;"
      >
        <h4 className="team-card-overlay-header">
          <b>{name}</b>
        </h4>
        <p
          className="team-card-overlay-name"
          dangerouslySetInnerHTML={{ __html: bio?.bio || '' }}
        />
        <SocialsIcons
          twitter={twitter || ''}
          linkedIn={linkedIn || ''}
          messageUrl={messageUrl || ''}
          containerStyle={{
            justifyContent: 'center',
            margin: '1em auto'
          }}
        />
      </ResponsiveOverlay>
    </>
  )
}

export default TeamCard
