import React from 'react'
import LogoOne from '../../images/client-logos/feh_client_logo_1.svg'
import LogoTwo from '../../images/client-logos/feh_client_logo_2.svg'
import LogoThree from '../../images/client-logos/feh_client_logo_3.svg'
import LogoFour from '../../images/client-logos/feh_client_logo_4.svg'
import LogoFive from '../../images/client-logos/feh_client_logo_5.svg'
import './CompaniesLogos.scss'

const logos = [
  {
    id: 1,
    image: LogoFive
  },
  {
    id: 2,
    image: LogoOne
  },
  {
    id: 3,
    image: LogoTwo
  },
  {
    id: 4,
    image: LogoThree
  },
  {
    id: 5,
    image: LogoFour
  }
]

const CompaniesLogos = () => (
  <div className="companies-container">
    <p className="companies-text">TRUSTED BY THESE COMPANIES</p>
    <div className="companies-images-section">
      {logos.map((logo) => (
        <img key={logo.id} alt={'...'} src={logo.image} className="companies-logos-images" />
      ))}
    </div>
  </div>
)

export default CompaniesLogos
