import { Link } from 'gatsby'
import React from 'react'
import Button from '../elements/Button'
import SocialsIcons from '../SocialsIcons'

const links = [
  {
    href: '',
    title: 'Home'
  },
  {
    href: 'about',
    title: 'About'
  },
  {
    href: 'services',
    title: 'Services'
  },
  {
    href: 'insights',
    title: 'Insights'
  },
  {
    href: 'contact',
    title: 'Contact'
  }
]

const NavLink = ({ isNavExpanded }: { isNavExpanded: boolean }) => (
  <div className="navigation-menu">
    <ul>
      {links.map((link) => (
        <li key={link.href}>
          <Link to={`/${link.href}`} activeStyle={{ color: '#DE172A' }}>
            {link.title}
          </Link>
        </li>
      ))}
      <li>
        {/* <a href="https://www.48h.co.za/vice/" target="_blank" rel="noreferrer"> */}
        <Link to={`/vice`}>
          {!isNavExpanded ? (
            <Button
              title="Vice"
              style={{
                fontFamily: 'GourmethearthRegular',
                cursor: 'pointer'
              }}
            />
          ) : (
            <span className="navigation-button-sm">Vice</span>
          )}
        </Link>
      </li>
      {isNavExpanded && (
        <li className="navigation-socials">
          <SocialsIcons twitter="https://twitter.com/48hcoza" />
        </li>
      )}
    </ul>
  </div>
)

export default NavLink
