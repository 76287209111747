import React, { ReactNode } from 'react'
import fehLogo from '../../images/feh_logo.svg'
import './index.scss'

const ResponsiveOverlay = ({
  className,
  show,
  children,
  onClick,
  closeContent,
  showLogo
}: {
  show: boolean
  showLogo?: boolean
  children: ReactNode
  onClick: () => void
  className?: string
  closeContent?: string
}) => (
  <div className="overlay" style={{ display: show ? 'block' : 'none ' }}>
    <div
      className={className}
      style={{
        ...(showLogo && {
          padding: '1rem'
        })
      }}
    >
      <div className="overlay-header">
        {showLogo && (
          <a href="/" className="overlay-brand-name">
            <img className="overlay-nav-img" src={fehLogo} alt="logo" />
          </a>
        )}
        <button
          onClick={onClick}
          className={`closebtn ${closeContent === 'CLOSE' ? 'closed' : 'open'}`}
        >
          {closeContent}
        </button>
      </div>
      <div
        className="overlay-content"
        style={{
          ...(closeContent !== 'CLOSE'
            ? {
                top: '0'
              }
            : {
                top: '-40px'
              })
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

export default ResponsiveOverlay
