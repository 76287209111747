import React from 'react'
import './index.scss'

import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player'

interface IProps {
  onComplete: (value: React.SetStateAction<boolean>) => void
}

const Loader: React.FC<IProps> = ({ onComplete }) => {
  const lottieData = 'https://lottie.host/b4db8dfc-c993-4dd7-8d03-8cdfe8572811/Jd6ri1YyD1.json'
  return (
    <div className="loader" id="loader-wrapper">
      <Player
        onEvent={(e: PlayerEvent) => {
          if (e === 'complete') onComplete(false)
        }}
        autoplay
        src={lottieData}
        style={{ height: '300px', width: '300px' }}
      />
    </div>
  )
}
export default Loader
