import * as React from 'react'
import { HeadFC } from 'gatsby'
import Layout from '../components/layout'
import Home from '../components/Home'
import HtmlHead from '../components/HtmlHead'
import Loader from '../components/elements/Loader'
import { useState } from 'react'

const IndexPage = () => {
  const isBrowser = typeof window !== 'undefined'
  const isFirstLoad = isBrowser && localStorage.getItem('isFirstLoad')
  const [showLoader, setShowLoader] = useState(!isFirstLoad)

  const handleAnimationComplete = () => {
    isBrowser && localStorage.setItem('isFirstLoad', 'false')
    setShowLoader(false)
  }

  return (
    <>
      {showLoader ? (
        <Loader onComplete={handleAnimationComplete} />
      ) : (
        <Layout>
          <Home />
        </Layout>
      )}
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <HtmlHead pageTitle="Home Page" />
