import * as React from 'react'
import Banner from '../Banner'
import './index.scss'
import ReachOut from '../ReachOut'

const youtubeURL = 'https://www.youtube.com/embed/WrfDvA0sPbM'

const Services = ({ data }: { data: Queries.ServicesPageQuery }) => {
  const { allContentfulTestimonials, allContentfulWhatWeDo } = data

  const testimonials = allContentfulTestimonials.edges
  const whatWeDo = allContentfulWhatWeDo.edges

  return (
    <div className="services-container">
      <Banner classModifier="services">
        <section className="services-banner-container">
          <div className="services-hero">
            <h3 className="services-hero-title">
              48Hours helps companies <strong>lower their risk </strong> of costly reputational
              harm.
            </h3>
          </div>
        </section>
      </Banner>

      <div className="what-we-do">
        <h4 className="title">What we do</h4>
        <div className="cards">
          {whatWeDo.map(({ node: { id, title, description } }) => (
            <div className="card" key={id}>
              <h5 className="title">{title}</h5>
              <p className="description">{description && description.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="video">
        <iframe
          title="48-hour-youtube"
          src={youtubeURL}
          frameBorder={0}
          allow="accelerometer; autoplay; encrypted-media; gyroscope;"
          allowFullScreen
        />
      </div>

      <div className="what-they-have-to-say">
        <h4 className="title">What they have to say</h4>
        <div className="cards">
          {testimonials.map(({ node: { id, image, description, name, jobTitle } }) => (
            <div className="card" key={id}>
              <img src={`https://${image?.file?.url}`} alt="Company logo" className="img" />
              <p className="description">{description && description.description}</p>
              <div>
                <p className="name">{name}</p>
                <p className="position">{jobTitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ReachOut>
        <h3 className="reach-out-header">
          <span className="reach-out-header-light">CONTROL THE NARRATIVE. </span>
          LET US START THE CONVERSATION WITH YOU FOR A BETTER TOMORROW.
        </h3>
      </ReachOut>
    </div>
  )
}

export default Services
