import React, { useState } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import ResponsiveOverlay from '../ResponsiveOverlay'
import NavLink from './NavLink'
import fegLogo from '../../images/feh_logo.svg'
import './index.scss'

type classModifiers = 'services'

const Navbar = ({ classModifier }: { classModifier?: classModifiers }) => {
  const { width } = useWindowDimensions()
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(false)

  return (
    <nav className={`navigation ${!isNavExpanded ? classModifier : ''}`}>
      <a href="/" className="brand-name">
        <img className="nav-img" src={fegLogo} alt="logo" />
      </a>
      <button
        className={`hamburger ${classModifier}`}
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        {isNavExpanded ? '' : 'Menu'}
      </button>
      {width <= 769 ? (
        <ResponsiveOverlay
          className="menu-nav"
          showLogo
          show={isNavExpanded}
          onClick={() => setIsNavExpanded(!isNavExpanded)}
          closeContent="CLOSE"
        >
          <NavLink {...{ isNavExpanded }} />
        </ResponsiveOverlay>
      ) : (
        <NavLink {...{ isNavExpanded }} />
      )}
    </nav>
  )
}

export default Navbar
