import React, { ReactNode } from 'react'
import './index.scss'
import Icon from '../elements/Icon'
import { navigate } from 'gatsby'

const ReachOut = ({ children }: { children: ReactNode }) => {
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    navigate('/contact')
  }

  return (
    <div className="react-out-container">
      <div className="react-out-section">
        {children}
        <div className="contact-section">
          <span className="contact-us-header">CONTACT US</span>
          <button onClick={handleSubmit} className="submit-arrow-container">
            <Icon id="right-arrow" className="icon" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReachOut
