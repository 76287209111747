import React, { ReactNode } from 'react'
import Navbar from '../Nav'
import './index.scss'

type classModifiers = 'services'

const Banner = ({
  classModifier,
  children
}: {
  classModifier?: classModifiers
  children: ReactNode
}) => (
  <div className={`banner-container ${classModifier || ''}`}>
    <Navbar classModifier={classModifier} />
    {children}
  </div>
)

export default Banner
