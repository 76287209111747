import React from 'react'

import './index.scss'

const SocialsIcons = ({
  twitter,
  linkedIn,
  messageUrl,
  containerStyle
}: {
  twitter: string
  linkedIn?: string
  messageUrl?: string
  containerStyle?: { [key: string]: string }
}) => (
  <div className="socials-icon-container" style={containerStyle}>
    <a
      href={twitter || ''}
      title="twitter"
      target="_blank"
      className="socials-twitter-img"
      rel="noreferrer"
    >
      {/* twitter */}
    </a>
    {linkedIn && (
      <a
        href={linkedIn || ''}
        title="linkedIn"
        target="_blank"
        className="socials-linkedIn-img"
        rel="noreferrer"
      >
        {/* linkedIn */}
      </a>
    )}
    {messageUrl && (
      <a
        href={`mailto:${messageUrl}`}
        title="message"
        target="_blank"
        className="socials-message-img"
        rel="noreferrer"
      >
        {/* email */}
      </a>
    )}
  </div>
)

export default SocialsIcons
