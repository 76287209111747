import React, { useRef } from 'react'
import Banner from '../Banner'
import Brands from './Brands'
import ProblemSolving from './ProblemSolving'
import CompaniesLogos from './CompaniesLogos'
import ReachOut from '../ReachOut'
import Solution from './Solution'
import group43 from '../../images/group-43.svg'
import Hour48Logo from '../../images/48_hours_logo_1.svg'
import arrowDown from '../../images/assets/icon/arrow-down.svg'
import Typewriter, { TypewriterClass } from 'typewriter-effect'

import './index.scss'

const Home: React.FC = () => {
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    scrollRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  return(
    <>
      <Banner>
        <div className="home-section-container">
          <section className="home-section">
            <div className="home-container">
              <div className="home-group">
                <div className="home-text-container">
                  <h2 className="header-text">
                    <Typewriter
                      onInit={(typewriter: TypewriterClass) => {
                        typewriter
                          .typeString('The first 48 hours are critical.')
                          .pauseFor(2000)
                          .deleteAll()
                          .typeString('Online crisis, real world solution.')
                          .pauseFor(2000)
                          .deleteAll()
                          .typeString('Control the narrative')
                          .pauseFor(2000)
                          .deleteAll()
                          .typeString('Keep calm and call us.')
                          .pauseFor(2000)
                          .deleteAll()
                          .typeString('Crisis averted')
                          .start()
                      }}
                    />
                  </h2>
                  <img className="header-43-img" src={group43} alt="..." />
                  <div className="header-bottom-section">
                    <div className="header-bottom-content">
                      <img className="header-bottom-content-img" alt="..." src={Hour48Logo} />
                      <div className="home-social-media">
                        <h3 className="home-crisis">
                          Social Media
                          <br />
                          Crisis Consultancy
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        <div className="scroll-down-container" onClick={executeScroll}>
          <div className="scroll-down">
            <img className="scroll-down-arrow" src={arrowDown} alt="..." />
            <div className="scroll-down-text">SCROLL DOWN</div>
          </div>
        </div>
        </div>
      </Banner>
      <div ref={scrollRef}>
        <Brands />
      </div>
      <ProblemSolving />
      <Solution />
      <CompaniesLogos />
      <ReachOut>
        <h3 className="reach-out-header">
          <span className="reach-out-header-light">CONTROL THE NARRATIVE. </span>
          Let&apos;s start a conversation and a new chapter in reputation management.
        </h3>
      </ReachOut>
    </>
  )
}

export default Home
