import { Link } from 'gatsby'
import React, { useState } from 'react'
import { contactUs } from '../../utils/api'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Button from '../elements/Button'
import Checkbox from '../elements/Checkbox'
import TextField from '../elements/TextField'
import TxtArea from '../elements/TxtArea'
import SubscribeMessage from '../SubscribeMessage'
import './FormSection.scss'

const FormSection = () => {
  const { width } = useWindowDimensions()

  const [loading, setLoading] = useState<boolean>(false)

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [subscribe, setSubscribe] = useState<boolean>(false)
  const [aboutUs, setAboutUs] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [response, setResponse] = useState({
    msg: '',
    result: '',
    error: false
  })

  const resetForm = () => {
    setName('')
    setEmail('')
    setCompanyName('')
    setSubscribe(false)
    setAboutUs('')
    setMessage('')
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    try {
      setLoading(true)
      await contactUs(email, name, aboutUs, companyName, message, subscribe)
      setResponse({
        result: 'Success',
        msg: 'Thank you for contacting us',
        error: false
      })
      setLoading(false)
      resetForm()
    } catch (error) {
      setLoading(false)
      setResponse({
        result: 'Error',
        msg: 'Something went wrong please contact. ',
        error: true
      })
    }
  }

  const renderErrorLink = () => {
    const emailAddress = 'info@48h.co.za'
    const formValues = `Email: ${email}%0D%0AName: ${name}%0D%0ACompany: ${companyName}%0D%0AHow did you hear about us?: ${aboutUs}%0D%0AMessage: ${message}`
    return (
      <a href={`mailto:${emailAddress}?subject=Contact us!&body=${formValues}`}>{emailAddress}</a>
    )
  }

  const style = {
    marginRight: 0
  }

  return (
    <div className="form-section-container" id="message-now">
      <h3 className="form-section-header">
        <span className="form-section-header-light">Alternatively,</span> fill in our form
      </h3>
      <form onSubmit={handleSubmit}>
        <TextField
          label="name"
          value={name}
          id="FNAME"
          name="FNAME"
          onChange={(e) => setName(e.target.value)}
          style={style}
          required
        />
        <TextField
          label="email"
          value={email}
          id="email"
          name="EMAIL"
          onChange={(e) => setEmail(e.target.value)}
          style={style}
          required
        />
        <TextField
          label="company name"
          value={companyName}
          name="COMPANY"
          id="COMPANY"
          onChange={(e) => setCompanyName(e.target.value)}
          style={style}
        />
        <TextField
          label="How did you hear about us"
          value={aboutUs}
          name="MMERGE7"
          id="MMERGE7"
          onChange={(e) => setAboutUs(e.target.value)}
          style={style}
        />
        <TxtArea
          label="message"
          value={message}
          name="MMERGE8"
          id="MMERGE8"
          onChange={(e) => setMessage(e.target.value)}
          style={style}
        />
        <div>
          <Checkbox
            name="newsletter"
            label="Subscribe to our newsletter."
            value={subscribe ? 'true' : 'false'}
            setValue={setSubscribe}
          />
        </div>
        <div className="form-section-bottom">
          <span className="form-section-privacy">
            View our{' '}
            <Link to="/privacy" className="form-section-privacy-light">
              Privacy Policy.
            </Link>
          </span>
          <Button
            title="SEND"
            type="submit"
            loading={loading}
            className="form-button-message"
            style={{
              ...(width > 740
                ? {
                    width: '20%'
                  }
                : {
                    width: '100%',
                    marginTop: '1em'
                  })
            }}
          />
        </div>
        {response.msg && (
          <SubscribeMessage>
            <>
              {response.msg}
              {response.error && renderErrorLink()}
            </>
          </SubscribeMessage>
        )}
      </form>
    </div>
  )
}

export default FormSection
