import { graphql } from 'gatsby'
import React from 'react'
import Banner from '../components/Banner'
import PostDetails from '../components/Blog/details'
import Layout from '../components/layout'

interface IPostDetailProps {
  data: Queries.singleBlogPostQueryQuery
}

const PostDetail: React.FC<IPostDetailProps> = ({ data }) => (
  <Layout>
    <Banner>
      <PostDetails {...{ data }} />
    </Banner>
  </Layout>
)

export default PostDetail

export const singleBlogPostQuery = graphql`
  query singleBlogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      tag
      id
      content {
        childMarkdownRemark {
          html
        }
      }
      src {
        url
        title
      }
      slug
      createdAt(formatString: "DD MMMM YYYY")
    }
    allContentfulBlogPost(filter: { slug: { ne: $slug } }) {
      distinct(field: slug)
      edges {
        node {
          title
          tag
          id
          content {
            content
          }
          src {
            url
            title
          }
          slug
        }
      }
    }
  }
`
