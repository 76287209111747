import React from 'react'
import brands1 from '../../images/brands-img-1.png'
import brands2 from '../../images/brands-img-2.png'
import './Brands.scss'

const Brands = () => (
  <div className="brands-container">
    <div className="brands-text-section">
      <h3 className="brands-text-header">
        Brands are used to speaking at customers,
        <br />
        <span className="brands-text-header-bold"> not with them.</span>
      </h3>
      <p className="brands-text">
        Social media gave customers a voice and changed everything. Despite having almost two
        decades to adapt to social media communication, companies worldwide still find themselves in
        crisis every day. The impact of these crises goes well beyond reputation.
      </p>
    </div>
    <div className="brands-images">
      <img className="brands-img-1" src={brands1} alt="..." />
      <img className="brands-img-2" src={brands2} alt="..." />
    </div>
  </div>
)

export default Brands
