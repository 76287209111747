import { Link } from 'gatsby'
import React from 'react'
import './index.scss'

interface ICardProps {
  item: Queries.allPostsQueryQuery['allContentfulBlogPost']['edges'][0]['node']
}

const Card: React.FC<ICardProps> = ({ item }) => (
  <div className={`card-container`}>
    <div className="card-container-image">
      <span className="card-container-image__tag">{item?.tag}</span>
      <Link to={`/insights/${item.slug}`}>
        <img
          className="card-container-image__src"
          src={item?.src?.url || ''}
          alt={item?.src?.title || ''}
        />
      </Link>
    </div>
    <div className="card-container-extra">
      <div className="card-container__title">
        <Link to={`/insights/${item.slug}`}>{item.title}</Link>
      </div>
      <div className="card-container__description">
        {item?.content?.content?.slice(0, 100).trim()}...
      </div>
    </div>
  </div>
)

export default Card
