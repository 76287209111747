import React from 'react'
import { graphql, HeadFC } from 'gatsby'
import Layout from '../components/layout'
import Services from '../components/Services'
import HtmlHead from '../components/HtmlHead'

interface IServicePageProps {
  data: Queries.ServicesPageQuery
}

const ServicesPage: React.FC<IServicePageProps> = ({ data }) => (
  <Layout>
    <Services {...{ data }} />
  </Layout>
)

export default ServicesPage

export const Head: HeadFC = () => <HtmlHead pageTitle="Services Page" />

export const servicesQuery = graphql`
  query ServicesPage {
    allContentfulWhatWeDo {
      edges {
        node {
          id
          title
          description {
            description
          }
        }
      }
    }
    allContentfulTestimonials {
      edges {
        node {
          id
          name
          jobTitle
          description {
            id
            description
          }
          image {
            id
            file {
              url
            }
          }
        }
      }
    }
  }
`
