import React, { ChangeEvent } from 'react'
import './index.scss'

type TTextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string
}

const TextField: React.FC<TTextFieldProps> = ({ id, value, label, onChange, style, required }) => {
  const [hasValue, setHasValue] = React.useState<boolean>(!!value)
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
    setHasValue(!!e.target.value)
  }

  return (
    <div className="textfield-container" style={style}>
      <input
        type="text"
        id={id}
        value={value}
        name={id}
        className={`textfield-input ${hasValue ? 'filled' : ''}`}
        onChange={handleOnChange}
        required={required}
      />
      <label htmlFor={id} className="textfield-label">
        {label}
      </label>
    </div>
  )
}

export default TextField
