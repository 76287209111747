import React from 'react'
import problemSolving1Img from '../../images/problemSolving-img-1.png'
import problemSolving2Img from '../../images/problemSolving-img-2.png'
import './ProblemSolving.scss'

const ProblemSolving: React.FC = () => (
  <div className="problemSolving-parent">
    <div className="problemSolving-container">
      <div className="problemSolving-images">
        <img className="problemSolving-img-1" src={problemSolving1Img} alt="..." />
        <img className="problemSolving-img-2" src={problemSolving2Img} alt="..." />
      </div>
      <div className="problemSolving-text-section">
        <h3 className="problemSolving-text-header">
          <span className="problemSolving-text-header-bold">48HOURS is your partner</span>
          <br />
          in solving this problem.
        </h3>
        <p className="problemSolving-text">
          We provide you with access to technology, insight and advice, to mitigate risk and damage
          in the midst of a crisis. But prevention is better than cure, so we also provide a range
          of consulting offerings to bolster communication and customer service capabilities to ward
          off potential crises.
        </p>
      </div>
    </div>
  </div>
)

export default ProblemSolving
