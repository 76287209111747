import React, { ReactElement } from 'react'
import './index.scss'

interface IProps {
  title: string
  children?: ReactElement
  style?: { [key: string]: string }
  onClick?: () => void
  type?: 'submit' | 'button'
  loading?: boolean
  disabled?: boolean
  className?: string
}

const Button = ({
  title,
  children,
  style,
  onClick,
  disabled,
  className,
  type = 'button',
  loading = false
}: IProps) => (
  <button
    className={className || 'button-container'}
    type={type}
    onClick={onClick}
    style={{
      ...style
    }}
    disabled={disabled || false}
  >
    {loading ? (
      <div className="spinner" />
    ) : (
      <>
        {title}
        {children}
      </>
    )}
  </button>
)

export default Button
