import * as React from 'react'
import type { HeadFC } from 'gatsby'
import Layout from '../components/layout'
import Contact from '../components/Contact'
import HtmlHead from '../components/HtmlHead'

const ContactPage = () => (
  <Layout>
    <Contact />
  </Layout>
)

export default ContactPage

export const Head: HeadFC = () => <HtmlHead pageTitle="Contact Page" />
