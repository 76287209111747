import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const HtmlHead = ({ pageTitle }: { pageTitle?: string }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const { title, description } = data.site.siteMetadata

  return (
    <>
      <title>{pageTitle || title}</title>
      <meta name="description" content={description} />
      <meta content="text/html;charset=utf-8" httpEquiv="Content-Type" />
      <meta content="utf-8" httpEquiv="encoding" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
      />
      <link rel="manifest" crossOrigin="use-credentials" href="/manifest.webmanifest" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="en-US" />
      <meta
        property="og:image"
        content="https://images.ctfassets.net/1k7853oleek9/3fSlaJNycg6tMLX404uH09/7005faf223efe1824a691b3de88d7209/feh_social_share__1_.png"
      />
      <meta
        property="og:image:secure_url"
        content="https://images.ctfassets.net/1k7853oleek9/3fSlaJNycg6tMLX404uH09/7005faf223efe1824a691b3de88d7209/feh_social_share__1_.png"
      />
      <meta property="og:image:alt" content="48h" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://48h.co.za/" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:creator" content={'Okies'} />
    </>
  )
}

export default HtmlHead
